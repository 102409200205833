import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Navigation, Home, About, Single } from './components';
import { MoralisProvider } from 'react-moralis';
import ContractProvider from './blockchain/ContractProvider';

function App() {
    return (
        <div className="App">
            <MoralisProvider
                appId="AXP4mNi52vhBSAIfDrpMPp40h5vMuQPz0hvMlY3f"
                serverUrl="https://br5kl4jviwuk.usemoralis.com:2053/server"
            >
                <ContractProvider>
                    <Router>
                        <Navigation />
                        <Switch>
                            <Route path="/" exact component={() => <Home />} />
                            <Route path="/about" exact component={() => <About />} />
                            <Route path="/single/:id" render={(props) => <Single {...props} />} />
                        </Switch>
                    </Router>
                </ContractProvider>
            </MoralisProvider>
        </div>
    );
}

export default App;
