import React, { Component } from "react";
import aboutImage from "../assets/images/about.png";

export default class About extends Component {
    render() {
        return (
            <div className="main">
                <section className="image-section mt-5">
                    <div className="container">
                        <div className="col-2">
                            <img src={aboutImage} alt="About" className="rounded" />
                        </div>
                    </div>
                </section>
                <section className="about-section">
                    <div className="container">
                        <div className="col-md-8 col-sm-12">
							<div className="row">
								<div className="col-md-8 col-sm-12 custom-about">
									<p>This project was made by artist FAR. </p>
									<p>Say hi on twitter, instagram, or email:</p>
								</div>
							</div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}