import React, { useState, useEffect, useContext, useCallback } from "react";
import { useMoralis } from "react-moralis";
import SeascapeDetails from "./Seascape.json";

export function useContract() {
    const w = useContext(ContractContext);
    if (!w) {
      throw new Error('Missing contract context');
    }
    return w;
}

const ContractContext = React.createContext(null);

export const NFT_CONTRACT_ADDRESS = "0xc05aB6B5d736B2ee93bBCb70c8dC7dA4188b798A";

export default function ContractProvider(props) {
    const { web3, isWeb3Enabled } = useMoralis();
    const [contract, setContract] = useState(null);

    const loadSeaPort = useCallback(async() => {
        if (isWeb3Enabled) {
            const nftContract = new web3.eth.Contract(SeascapeDetails.abi, NFT_CONTRACT_ADDRESS);
            setContract(nftContract);
        }
    }, [isWeb3Enabled, web3, setContract]);
    useEffect(() => { loadSeaPort() }, [loadSeaPort, isWeb3Enabled]);


    return (
        <ContractContext.Provider value={{ contract }}>
            {props.children}
        </ContractContext.Provider>
    )
}