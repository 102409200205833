import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useMoralis } from 'react-moralis';

function Navigation(props) {
    const { authenticate, isAuthenticated, logout, user, enableWeb3, isWeb3Enabled } = useMoralis();

    const loadWeb3 = async () => {
        await enableWeb3();
    };
    useEffect(() => {
        if (isWeb3Enabled && !isAuthenticated) {
            authenticate();
        }
    }, [isWeb3Enabled, authenticate, isAuthenticated]);

    const setupUser = () => {
        if (isAuthenticated) {
            logout();
        } else {
            loadWeb3();
        }
    };

    return (
        <section className="main-header">
            <div className="container">
                <nav className="navbar navbar-light">
                    <div className="form-inline">
                        <Link className="btn btn-left " to="/">mSeascapes(s)</Link>
                        <Link className="btn btn-left" to="/about">About</Link>
                    </div>
                    <Link className="btn btn-right" to="#" onClick={() => setupUser()}>{(user && user.get('ethAddress')) || 'Connect Wallet'}</Link>
                </nav>
            </div>
        </section>
    );
}

export default withRouter(Navigation);