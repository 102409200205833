import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import { useContract, NFT_CONTRACT_ADDRESS } from "../blockchain/ContractProvider";
import { useMoralis } from 'react-moralis';
import { wavesData } from "./waves";

export default function Single(props) {
    const { isAuthenticated, web3, user } = useMoralis();
    const { contract } = useContract();

    const [isMinted, setMinted] = useState(false);

    useEffect(() => {
        (async () => {
            const status = contract && await contract.methods.isMinted(props.match.params.id).call();
            console.log(status);
            setMinted(status);
        })();
    }, [contract, props.match.params.id]);

    const viewOnOpensea = () => {
        window.open(
            'https://opensea.io/assets/' + NFT_CONTRACT_ADDRESS + '/' + props.match.params.id,
            '_blank'
        );
    };

    const mint = () => {
        if (isAuthenticated) {
            if (!isMinted) {
                contract &&
                    contract.methods
                        .mintTo(user.get('ethAddress'), props.match.params.id)
                        .send({ from: user.get('ethAddress'), value: web3.utils.toWei('0.1', 'ether') });
            } else {
                viewOnOpensea()
            }
        }
    };

    return (
        <div className="main">
            <section className="product-page">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="img">
                                <img src={wavesData[props.match.params.id-1].image} alt="Product"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-6">
                                <div className="card-body-custom">
                                    <div className="main-text">
                                        <h5 className="card-heading">mSeascape #{props.match.params.id}</h5>
                                        <h5 className="card-title ">0.1 Eth</h5>
                                    </div>
                                    <Link className="btn btn-mint" type="button" to="#" onClick={() => mint()}>{isMinted ? "View on Opensea" : "Mint" }</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}