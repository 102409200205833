import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useContract } from "../blockchain/ContractProvider";
import { wavesData } from "./waves";

export default function Home() {
    const [ waves ] = useState(wavesData);

    const { contract } = useContract();

    const [isMinted, setMinted] = useState(Array(waves.length).fill(false));

    useEffect(() => {
        (async () => {
            let minted = []
            for (let i = 1; i <= waves.length; i++) {
                const status = contract && await contract.methods.isMinted(i).call();
                minted.push(status);
            }
            setMinted(minted);
        })();
    }, [contract, setMinted, waves.length]);
    
    return (
        <div className="main">
            <section className="img-logo">
                <div className="container">
                    <div className="img-fluid mt-5 logo-img"><img src={logo} alt=""/></div>
                </div>
            </section>
            <section className="text-heading">
                <div className="container mt-4">
                    <div className="h6 mt-4">The Sea as I Remember</div>
                    <div className="h6 mt-5">This limited collection of Seascapes were created by a combination of wave simulations using multi physics <br/> software simulation, Artificial Intelligence, coding, and 2D creation tools.
                    </div>
                </div>
            </section>
            <section className="product-section mt-5">
                <div className="container">
                    <div className="row">
                        {waves.map((data) => (
                            <div className="col-md-3" key={data.id}>
                                <div className="column">
                                    <div className="col p-0 order-1">
                                        <div className="img">
                                        <Link className="custom-link" to={`/single/${data.id}`} params={{ id: data.id }}>
                                            <img src={data.image} alt="mSeascape #{data.id}"/>
                                        </Link>
                                        </div>
                                        <div className="card-body">
                                            <div className="main-text d-flex">
                                                <h5 className="card-title">mSeascape #{data.id}</h5>
                                                <h5 className="card-title-price ">{data.price} Eth</h5>
                                            </div>
                                            <Link className="btn btn-mint" to={`/single/${data.id}`} params={{ id: data.id }}>Mint</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}