import waveImage1 from '../assets/images/waves1.png';
import waveImage2 from '../assets/images/waves2.png';
import waveImage3 from '../assets/images/waves3.png';
import waveImage4 from '../assets/images/waves4.png';
import waveImage5 from '../assets/images/waves5.png';
import waveImage6 from '../assets/images/waves6.png';
import waveImage7 from '../assets/images/waves7.png';
import waveImage8 from '../assets/images/waves8.png';
import waveImage9 from '../assets/images/waves9.png';
import waveImage10 from '../assets/images/waves10.png';
import waveImage11 from '../assets/images/waves11.png';
import waveImage12 from '../assets/images/waves12.png';

export const wavesData = [
    { id: 1, image: waveImage1, price: 0.1 },
    { id: 2, image: waveImage2, price: 0.1 },
    { id: 3, image: waveImage3, price: 0.1 },
    { id: 4, image: waveImage4, price: 0.1 },
    { id: 5, image: waveImage5, price: 0.1 },
    { id: 6, image: waveImage6, price: 0.1 },
    { id: 7, image: waveImage7, price: 0.1 },
    { id: 8, image: waveImage8, price: 0.1 },
    { id: 9, image: waveImage9, price: 0.1 },
    { id: 10, image: waveImage10, price: 0.1 },
    { id: 11, image: waveImage11, price: 0.1 },
    { id: 12, image: waveImage12, price: 0.1 },
];
